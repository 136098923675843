@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.PromoSmall';
$height: 197px;
$heightXS: 190px;

#{$name} {
    color: $white;

    &-wrapper {
        overflow: hidden;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            bottom: -1px; // For some reason a thin line shows if set to 0
            right: -1px;
            border-left: 10px solid transparent;
            border-top: 10px solid transparent;
            border-bottom: 10px solid $white;
            border-right: 10px solid $white;
        }
    }

    &-inner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: $heightXS;
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            min-height: $height;
        }
    }

    &-link {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: $heightXS;

        @include media-breakpoint-up(sm) {
            min-height: $height;
        }

        &:link, &:visited, &:active &:hover {
            color: $white;
            text-decoration: none;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: $primary;
            opacity: .8;
            z-index: -1;
            transition: all linear .2s;

            @include media-breakpoint-up(sm) {
                opacity: 0;
            }
        }

        &:hover {
            transition: all linear .2s;

            @include media-breakpoint-up(sm) {
                &::after {
                    opacity: .8;
                }
            }
        }
    }

    &-title {
        margin: 1rem 1rem .5rem;
        font-size: 1.25rem;


        @include media-breakpoint-up(md) {
            margin-left: 2rem;
            margin-right: 2rem;
            font-size: 1.125rem;
        }
    }

    &-titleInner {
        background-color: transparent;
        padding: 0 5px;
        box-decoration-break: clone;
        transition: background-color .4s;

        @include media-breakpoint-up(sm) {
            background-color: $primary;
        }

        #{$name}-link:hover & {
            background-color: transparent;
        }
    }

    &-intro {
        font-size: 1rem;
        margin: 0 1em .7rem;

        @include media-breakpoint-up(md) {
            margin: 0 2rem .7rem;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 3rem .7rem;
        }
    }

    &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* stylelint-disable font-family-no-missing-generic-family-keyword  */
        font-family: 'object-fit: cover;';
        /* stylelint-enable */
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) scale(1, 1);
        max-width: 100%;
        z-index: 0;
        transition: all linear .3s;
        backface-visibility: hidden;
    }

    &-hiddenWrapper {
        @include media-breakpoint-up(sm) {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all linear .4s;
            //padding-top: 0;
            #{$name}:hover & {
                opacity: 1;
                max-height: 200px;
            }
        }
    }

    &-buttonWrapper {
        margin-bottom: 1rem;
    }
}
